import axios from "axios";
import axiosInstance from "../helpers/axiosIntercepter";

export const loginService = async (values) => {
  try {
    const response = await axiosInstance.post(
      "/authentication/login",
      values
    );
    return response;
  } catch (error) {
    console.error("Error fetching active programs:", error);
    throw error;
  }
};

export const forgetPasswordService = async (values) => {
  try {
    const response = await axiosInstance.post(
      "/authentication/forgot-password",
      values
    );
    return response;
  } catch (error) {
    console.error("Error fogot password :", error);
    throw error;
  }
};
