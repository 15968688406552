import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  ArrowRightOnRectangleIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import logo from "../../assets/logo.png";
import "../../assets/styles/Navbar.css";
// import UserMenu from "./UserMenu";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

const Navbar = () => {
  const [hasShadow, setHasShadow] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
   const today = new Date();
  //  const formattedDate = format(today, "do MMMM yyyy", { locale: fr });
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formattedDateTime = format(currentTime, "do MMMM yyyy, HH:mm:ss", {
    locale: fr,
  });

  const MenuItems = [
    { name: "Accueil", link: "" },
    { name: "À propos", link: "/a-propos" },
    // { name: "Appel à candidature", link: "/appels-a-candidature" },
    { name: "Contact", link: "/contact" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setHasShadow(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isActive = (link) =>
    location.pathname === link || location.pathname.startsWith(link);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  return (
    <div
      className={`sticky top-0 bg-white z-50 ${hasShadow ? "shadow-sm" : ""}`}
    >
      <div className="bg-[#0976BC] h-8 w-full mb-1 md:flex ">
        <div className="md:w-[80%] mx-auto flex justify-center md:justify-between items-center text-white px-2 h-full py-1 ">
          <div className="text-[13px]  hidden md:flex ">
            {" "}
            Kinshasa, le {formattedDateTime}
          </div>
          <div className="flex gap-3 items-center text-sm ">
            <Link
              to="https://www.youtube.com/channel/UC1BwSinfI5PsZoWIoE3mOvg"
              className="no-underline text-white hover:scale-110 hover:-translate-y-1 target"
              target="_blank"
            >
              <i class="bi bi-youtube"></i>
            </Link>
            <Link
              to="https://facebook.com/rdctransforme"
              className="no-underline text-white hover:scale-110 hover:-translate-y-1"
              target="_blank"
            >
              <i class="bi bi-facebook"></i>
            </Link>
            <Link
              to="https://twitter.com/RdcTransforme"
              className="no-underline text-white hover:scale-110 hover:-translate-y-1"
              target="_blank"
            >
              <i class="bi bi-twitter-x"></i>
            </Link>
            <Link
              to="https://www.instagram.com/rdctransforme/"
              className="no-underline text-white hover:scale-110 hover:-translate-y-1"
              target="_blank"
            >
              <i class="bi bi-instagram"></i>
            </Link>
            <Link
              to="https://www.linkedin.com/showcase/projet-transforme-rdc/"
              className="no-underline text-white hover:scale-110 hover:-translate-y-1"
              target="_blank"
            >
              <i class="bi bi-linkedin"></i>
            </Link>
            {/* <Link
              to="#"
              className="no-underline text-white hover:scale-110 hover:-translate-y-1"
              target="_blank"
            >
              <i class="bi bi-threads"></i>
            </Link> */}
            <Link
              to="https://www.tiktok.com/@padmpmetransforme"
              className="no-underline text-white hover:scale-110 hover:-translate-y-1"
              target="_blank"
            >
              <i class="bi bi-tiktok"></i>
            </Link>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center w-full md:w-[80%] mx-auto py-2 px-2 md:px-0 relative z-50">
        <Link to="">
          <img
            src={logo}
            alt="logo-e-entrepreunariat"
            // className="h-[35px] w-[200px]"
            width={160}
            // height={52}
          />
        </Link>

        <div className="md:hidden">
          <button onClick={toggleMenu}>
            {isMenuOpen ? (
              <XMarkIcon className="h-8 w-8" />
            ) : (
              <Bars3Icon className="h-8 w-8" />
            )}
          </button>
        </div>

        <div className="hidden md:flex gap-5 items-center">
          {MenuItems.map((menuItem) => (
            <NavLink
              key={menuItem.link}
              to={menuItem.link}
              className={({ isActive }) =>
                `no-underline nav-item flex gap-2 my-3 ${
                  isActive ? "active" : ""
                }`
              }
            >
              {menuItem.name}
            </NavLink>
          ))}
        </div>

        <div className="hidden md:flex invisible">
          {localStorage.getItem("access-token") ? (
            <NavLink
              to="/coach-dashboard"
              className="bg-[#0976BC] text-white no-underline rounded-full px-4 py-2 hover:bg-[#065A94] transition-colors duration-300 max-w-fit"
            >
              Mon espace
            </NavLink>
          ) : (
            <Link
              to="/auth/login"
              className="bg-[#0976BC] text-white no-underline rounded-full px-4 py-2 hover:bg-[#065A94] transition-colors duration-300"
            >
              Se connecter
            </Link>
          )}
        </div>
      </div>

      {/* ---------- Mobile Menu ------------------- */}
      <div
        className={`fixed inset-0 bg-white transition-transform duration-300 ease-in-out z-40 md:hidden ${
          isMenuOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="flex flex-col items-left space-y-6 mt-24 ps-4 pt-5">
          {MenuItems.map((menuItem) => (
            <NavLink
              key={menuItem.link}
              to={menuItem.link}
              className={({ isActive }) =>
                ` nav-item max-w-fit text-lg font-semibold no-underline ${
                  isActive ? "active" : "text-gray-800"
                }`
              }
              onClick={toggleMenu}
            >
              {menuItem.name}
            </NavLink>
          ))}
          {/* {localStorage.getItem("access-token") ? (
            <NavLink
              to="/coach-dashboard"
              className="bg-[#0976BC] text-white no-underline rounded-full px-4 py-2 hover:bg-[#065A94] transition-colors duration-300 max-w-fit"
            >
              Mon espace
            </NavLink>
          ) : (
            <Link
              to="/auth/login"
              onClick={toggleMenu}
              className="bg-[#0976BC] text-white no-underline rounded-full px-4 py-2 hover:bg-[#065A94] transition-colors duration-300 max-w-fit "
            >
              Se connecter
            </Link>   
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
